const asynchronouslyImportScript = async (cssSelector: string, path: string) => {
  const elements = document.querySelectorAll<HTMLElement>(cssSelector);
  if (elements.length) {
    const script = await import(`@templates/${path}`);
    elements.forEach((element) => script.default.init(element));
  }
};

export default async () => {
  await asynchronouslyImportScript('.js-gridOverlay', '_components/gridOverlay/gridOverlay');
  await asynchronouslyImportScript('.js-navigation', '_components/navigation/navigation');
  await asynchronouslyImportScript('.js-lightboxGallery', '_sections/lightboxGallery/lightboxGallery');
  await asynchronouslyImportScript('.js-slider', '_embeds/slider/slider');
  await asynchronouslyImportScript('.js-cookieOverlay', '_components/cookieOverlay/cookieOverlay');
  await asynchronouslyImportScript('.js-video', '_components/video/video');
  await asynchronouslyImportScript('.js-quote', '_sections/quoteSlider/quoteSlider');
  await asynchronouslyImportScript('.js-locationsMap', '_sections/locationsMap/locationsMap');
  await asynchronouslyImportScript('.js-oilCollectionPoint', '_sections/oilCollectionPoint/oilCollectionPoint');
  await asynchronouslyImportScript('.js-stickyContacts', '_components/stickyContacts/stickyContacts');
  await asynchronouslyImportScript('.js-intro', '_sections/intro/intro');
  await asynchronouslyImportScript('.js-factBox', '_embeds/factBox/factBox');
  await asynchronouslyImportScript('.js-accordion', '_sections/faqAccordion/faqAccordion');
};
